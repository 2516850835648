import { FC } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import useSettings from "hooks/useSettings";
import { createCustomTheme } from "./theme";
import "./i18n";
import Router from "./Router";
import { THEMES } from "./constants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			enabled: false,
			refetchOnWindowFocus: false,
		},
	},
});

const App: FC = () => {
	const { settings } = useSettings();

	const theme = createCustomTheme({
		theme: THEMES.DARK,
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
	});

	return (
		<StyledEngineProvider injectFirst>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Router />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop
						closeOnClick
						rtl={settings.direction === "rtl"}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme={THEMES.DARK === settings.theme ? "dark" : "light"}
					/>
				</ThemeProvider>
			</QueryClientProvider>
		</StyledEngineProvider>
	);
};

export default App;
